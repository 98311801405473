var mrcChartRenderer = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';
     function loadEcharts(chartObj, callback){
          var loadTheme = chartObj.theme != 'mrc' && chartObj.theme != '';
          if (!loadjs.isDefined('echarts')) {
			// @ts-ignore
			var scripts = ['../assets/vendor/echarts/echarts.min.js'];
               if(!!loadTheme){
                    scripts.push('../assets/vendor/echarts/theme/' + chartObj.theme + '.js');
               }
               loadjs(scripts, 'echarts', { async: false });
               
		}

          /**Once fusion charts is loaded, create the chart */
		// @ts-ignore
		loadjs.ready('echarts', function fusionChartsLoaded () {
              callback(chartObj);
          });
     }
	function render(chartObj){
          var loadTheme = chartObj.theme != 'mrc';
          if (!loadjs.isDefined('echarts')) {
			// @ts-ignore
			var scripts = ['../assets/vendor/echarts/echarts.min.js'];
               if(!!loadTheme){
                    scripts.push('../assets/vendor/echarts/theme/' + chartObj.theme + '.js');
               }
               loadjs(scripts, 'echarts', { async: false });
               
		}

          /**Once fusion charts is loaded, create the chart */
		// @ts-ignore
		loadjs.ready('echarts', function fusionChartsLoaded () {
              /* FusionCharts.ready(function(){
                    var fusioncharts = new FusionCharts(chartObj);
                         fusioncharts.render();
               });*/
               var chartDom = document.getElementById(chartObj.renderAt);
               
               checkFunctionProperties(chartObj);
               
               jQuery(chartDom).height(chartObj.h).width(chartObj.w);
               var myChart = echarts.init(chartDom, chartObj.theme);
               myChart.setOption(chartObj);

               myChart.on("click", function (event) {
                    if(!!event.data.link){
                         var target = event.data.linkTarget || '_self';
                         window.open(event.data.link, target).focus();
                    }
               });


          });
     }
     function setSize(chartObj, chartDom){
          var height = chartObj.h;
          jQuery(chartDom).height(height).width(chartObj.w);
     }
     function checkFunctionProperties(obj) {
          Object.keys(obj).forEach(function(key) {
               if(!!obj[key].arguments){
                    obj[key] = new Function(obj[key].arguments, obj[key].body);
               }else{
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                         checkFunctionProperties(obj[key])
                    }
               }
          })
      }
	return {
          render,
          loadEcharts,
          setSize,
          checkFunctionProperties
     };
}(window, jQuery));
