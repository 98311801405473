/**
 * app_type codes used in this file
 * RNK - Ranking Report
 * R - Regular Report
 * C - Calendar
 * I - Multi/single record inquiry
 * WW - Work With Maintainer
 * M - Single Record Maingtainer
 * GRIDADD - Grid Add maintainer
 */

function getJSVersion () {
	// @ts-ignore
	if (!window.MRCCONSTANTS || !window.MRCCONSTANTS.VERSION) return 0;

	// @ts-ignore
	return window.MRCCONSTANTS.VERSION;
}

function handleAjaxForms (ev) {
	var form = ev.currentTarget;
	var $form = jQuery(form);
	/** @type {string | number} */
	var ajaxForm = $form.find('input[name="ajax_form"]').val();
	var redirect;

	ajaxForm = parseInt(ajaxForm, 10) || 0;

	if (ajaxForm === 0) return true;

	redirect = ajaxForm === 1 && checkMaintenanceRedirect($form[0]);
	if (redirect) return true;

	// 2014-07-25:if to-excel image click, send excel (21842)
	var toExcelVal = jQuery('#toexcel').val();
	toExcelVal =  parseInt(toExcelVal, 10) || 0;

	if (toExcelVal === 1) return true; // just submit form

	ev.preventDefault();
	submitViaAjax(form);

	return false;
}

jQuery(window).bind('load', function () {
	// multiple record and work with forms
	jQuery('form[data-app_type="I"]').each(function (i, el) {
		setFixedPagination(el);
	});

	// multiple record and work with forms
	jQuery('form[data-app_type="WW"]').each(function (i, el) {
		if (jQuery(el).attr('data-app_type') === 'WW') setFixedPagination(this);
	});
});

jQuery(function () {
	var refreshInterval = {}; // eslint-disable-line no-unused-vars

	// paging image buttons, need to add attribute for ajax submit
	jQuery('body').on('click', 'form :input[type="image"], form :input[name="btn_mnt"], form :input[name="btn_search"],form :input[name="btn_accept"]', function (ev) {
		var $input = jQuery(ev.currentTarget);
		jQuery(':input[type="image"], :input[name="btn_mnt"], :input[name="btn_search"], :input[name="btn_accept"]', $input.parents('form')).removeAttr('clicked');
		$input.attr('clicked', true);
		copyInputs();
	});

	// Forms with ajax_form property enabled, register submit listener
	jQuery('body').on('submit', 'form:has(input[name="ajax_form"])', handleAjaxForms);

	// Sort arrow, sort event listener
	jQuery('body').on('click', '.sort_image', function (ev) {
		var sortImage = jQuery(this);

		setSortAndSubmit(sortImage);
	});

	jQuery('body').on('click', '.clear_form_selections', function (ev) {
		jQuery(this).closest('form').find(':input[name^="val_"], .scombobox-display').val('');
	});

	jQuery('input[name="auto_refresh"]').each(function (i, el) {
		var $input = jQuery(el);
          var formInfo = formHandler.getForm(el);
          var $form = formInfo.$form;
		var ajaxForm =$form.find('input[name="ajax_form"]').val();
		if(ajaxForm != 0){//refresh for ajax form type submit handled by resetForm function
			return false;
		}
		var seconds = parseInt($input.val(), 10) || 0;

		$form.find('.auto_refresh_select').val(seconds);

		if (seconds > 0) {
			var interval = seconds * 1000;
              
			setTimeout(function () { 
                    if(!formInfo.isForm) {
                         $form.find('input[name="pageNum"]').val(1);
                         formHandler.doSubmit( $input );
                         return;
                    }
				$input.closest('form').find('input[name="pageNum"]').val(1);
				$input.closest('form').submit();
			}, interval);
		}
	});

	// Sort arrow sort event for maintainer
	jQuery('body').on('click', '.sort_image_maint', function (ev) {
		var sortImage = jQuery(this);

		setSortAndSubmitMaint(sortImage);
	});

	// Sort arrow sort event for IRT
	jQuery('body').on('click', '.sort_image_irt', function (ev) {
		var sortImage = jQuery(this);

		setSortAndSubmitIrt(sortImage);
	});

	jQuery('body').on('click', '.page-button', function (ev) {
		var formInfo = formHandler.getForm(ev);
		resetPaging(formInfo.$form);
		doAppPaging(this);
	});

	// Sort arrow, for upgraded retrieval templates
	jQuery('body').on('click', '.sort-col', function (ev) {
		var sortImage = jQuery(this);

		setSortAndSubmit2(sortImage);
	});

	// 2013-01-09 - add data-ajaxcall="true" to anchor tags to call them via ajax
	/* global ajaxLinkCallback */
	jQuery('body').on('click', 'a[data-ajaxcall="true"]', function (ev) {
		ev.preventDefault();
		var href = jQuery(this).attr('href');
		href += (href.indexOf('?') === -1 ? '?' : '&');

		href += 'x=' + Math.random();
		jQuery.get(href, function (data) {
			try {
				// @ts-ignore
				ajaxLinkCallback(data); // Call if registered
			} catch (err) {
				/* NOOP */
			}
		});

		return false;
	});

	// If old JS, call resetForm here on page load
	if (getJSVersion() === 0) {
		jQuery('form').each(function (i, el) {
			resetForm(el);
		});
	}

	// Select anchor tags in a ww maintainer retrieval form that is work_with= 1 and is ajax form submit, make them work via ajax
	jQuery('body').on('click', 'form[data-app_type="WW"]:has(input[name="ajax_form"][value!="0"]):has(input[name="work_with"][value="1"]) a.maint_action', function (ev) {
		ev.preventDefault();
		var href = jQuery(this).attr('href');
		var parent = jQuery(this).parents('.ajax_content').attr('data-work-with', '1').get(0);
		var index = href.indexOf('?');
		var amporq = '&';

		if (index === -1) amporq = '?';

		href = href + amporq + 'async=1&x=' + Math.random();

		loadViaAjax(parent, href, '', 'GET', null);
	});

	// Selection dialog selections button to open dialog
	jQuery('body').on('click', 'form .selections', function (ev) {
		var isdash = jQuery(this).parents('.tab').length;
		var form = jQuery(this).parents('form').get(0);
		var myform = jQuery(form);

		if (isdash > 0) {
			var dialog = myform.find('.selection_dialog').eq(0);
			dialog.show();
			var random = Math.random().toString().substring(2, 10);

			if (myform.attr('data-id') === undefined) {
				myform.attr('data-id', random);
				dialog.attr('data-id', random);
			}

			myform.parents('.tab').append(dialog);
		} else {
			// @ts-ignore
			myform.find('.selection_dialog').toggle().draggable({ handle: '.selection-title' });
		}
	});
	// Selection dialog ok and cancel buttons, close dialog when clicked
	jQuery('body').on('click', '.cancel_button, .selections_close', function (ev) {
		var dialog = jQuery(this).parents('.selection_dialog').eq(0);
		var id = dialog.attr('data-id');
		jQuery('form[data-id="' + id + '"]').append(dialog);
		jQuery(this).parents('.selection_dialog').toggle();
	});

	// Selection dialog handlers for report, load report prompt
	jQuery('body').on('click', '.load_prompt', function (ev) {
		var random = Math.random().toString().substring(2, 10);
		var report_container = jQuery(this).parents('.ajax_content').attr('data-id', random).get(0);

		loadReportPrompt(report_container, random);
	});

	// Report prompt selection dialog OK button clicked, submit form
	jQuery('body').on('click', '.ok_button_report', function (ev) {
		var $button = jQuery(ev.currentTarget);
		var $dialog = $button.parents('.selection_dialog').first();
		if(!doRequiredCheck($dialog)){
			return false;
		}
		var $parentForm = $dialog.parents('.ajax_content').find('form');

		if ($parentForm.find('input[name="ajax_form"][value!="0"]').length > 0) addLoadingMessageToContainer($dialog);

		copyInputs();
		submitReportPrompt($dialog[0]);
	});

	// Ranking report submit handler
	jQuery('body').on('submit', 'form[data-app_type="RNK"]', function (ev) {
		submitRankingPrompt(this);
	});

	jQuery('body').on('click', 'form[data-app_type="GRIDADD"]', function (ev) { // Grid add maintainer
		if (ev.keyCode === 13) {
			ev.preventDefault();
			return false;
		}
	});

	jQuery('form[data-app_type="GRIDADD"]').each(function (i, el) { // Grid add maintainer
		resetGridAdd(this);
	});

	/* global deleteGridRow */
	// @ts-ignore
	jQuery('body').on('click', '.delete_row', deleteGridRow); // _grid_add.js, line 4

	setupPeriodAnalysis();
});

function setFixedPagination (form) {
	var windowWidth = jQuery(window).width();

	jQuery(form).find('.data-table').each(function (i, el) {
		var offset = jQuery(el).offset().left;
		var tableWidth = jQuery(el).width();
		var adjust = (tableWidth + offset) - windowWidth;
		if ((tableWidth + offset) > windowWidth) {
			jQuery(el).find('.fixed-pagination').css('position', 'relative').css('right', adjust + 'px');
		}
	});
}

/* global pickerOptions */
function resetGridAdd (form) {
	var formType = jQuery(form).attr('data-app_type');

	if (formType !== 'GRIDADD') return;

	setAjaxAutoFill(form);

	// 2012-11-21:hide delete icon if there is only 1 row (18843)
	var rows = jQuery('.delete_row').length;
	if (rows === 1) jQuery('.delete_row').hide();

	try {
		// @ts-ignore
		jQuery(form).find('#addgrid_row').addGridRowHandler(form, pickerOptions);
	} catch (err) {
		/* NOOP */
	}

	// @ts-ignore
	jQuery(form).find('a[mrc=returndata],.rtnlnk').setRowNumberForReturnData();

	jQuery(form).find('input[type="reset"]').click(function (event) {
		var rows = jQuery('.grid_row').length; // set max row to the real rows

		setTimeout(function () {
			jQuery('#max_rows').val(rows);
		}, 10);

		// prevent added seq number to reset to the original. (18844)
		jQuery(form).find('input[added_autoseq_field="y"]').each(function (i, el) {
			var $obj = jQuery(el);
			var seqnum = $obj.val();

			setTimeout(function () {
				$obj.val(seqnum);
			}, 10);
		});
	});
}

function checkMaintenanceRedirect (form) {
	var $maintForm = jQuery(form);
	var mode = $maintForm.find('#action_mode').val();
	var $redirectInput;
	var redirectURL;
	var ajaxRedirect;

	if (!mode) return false;

	$redirectInput = $maintForm.find('input[name="all_redir"]');
	redirectURL = $redirectInput.val();
	redirectURL = (redirectURL && redirectURL.toString()) || '';
	ajaxRedirect = $redirectInput.data('ajax');
	if (jQuery.trim(redirectURL) !== '' && ajaxRedirect != '1') return true;

	if (mode === 'AVK' || mode === 'AUR') {
		$redirectInput = $maintForm.find('input[name="add_redir"]');
		redirectURL = $redirectInput.val();
		redirectURL = (redirectURL && redirectURL.toString()) || '';
		ajaxRedirect = $redirectInput.data('ajax');

		if (jQuery.trim(redirectURL) !== '' && ajaxRedirect != '1') return true;
	} else if (mode === 'UVK' || mode === 'URD') {
		$redirectInput = $maintForm.find('input[name="upd_redir"]');
		redirectURL = $redirectInput.val();
		redirectURL = (redirectURL && redirectURL.toString()) || '';
		ajaxRedirect = $redirectInput.data('ajax');

		if (jQuery.trim(redirectURL) !== '' && ajaxRedirect != '1') return true;
	} else if (mode === 'DVK' || mode === 'DDR') {
		$redirectInput = $maintForm.find('input[name="dlt_redir"]');
		redirectURL = $redirectInput.val();
		redirectURL = (redirectURL && redirectURL.toString()) || '';
		ajaxRedirect = $redirectInput.data('ajax');

		if (jQuery.trim(redirectURL) !== '' && ajaxRedirect != '1') return true;
	}

	return false;
}

/**
 * When report output 'make additional selections button is clicked, the prompt page is loaded. This function
 * handles the submission of the prompt when on theoutput page
 */
function submitReportPrompt (dialog) {
	var form = /** @type {HTMLFormElement} */ (jQuery(dialog).find('form').get(0));
	var outfmt = jQuery(form).find('input[name=outfmt]:checked').val();
	var type = jQuery(form).attr('data-app_type');

	if (type === 'RNK') submitRankingPrompt(form);

	var parentForm = jQuery(dialog).parents('.ajax_content').find('form');
	if (jQuery(dialog).parents('.tab').length > 0) {
		var randomid = jQuery(dialog).attr('data-parent-id');
		parentForm = jQuery('div[data-id="' + randomid + '"]').find('form');
	}

	if (parentForm.find('input[name="ajax_form"][value!="0"]').length > 0 && outfmt == 0) {
		submitViaAjax(form);
	} else {
		form.submit();
	}
}

/**
 * When report output 'make additional selections button is clicked, load the prompt page into a dialog and show it
 */
/* global buildComboboxes, initializeAjaxHelpers, initsetup */
function loadReportPrompt (report_container, randomId) {
	var original_report_container = report_container;
	var dialog = createReportPromptDialog(report_container, randomId);

	if (jQuery(report_container).parents('.tab').length > 0) {
		var portlet = jQuery(report_container).parents('.portlet').eq(0);
		var top = jQuery(portlet).position();
		report_container = jQuery(report_container).parents('.tab').eq(0);
		jQuery(report_container).append(dialog);
		dialog.css('top', top.top);
	}

	var form = jQuery(original_report_container).find('form');
	var action = form.attr('action');
	var formid = form.attr('name');
	var isCrossTab = form.find('input[name="C1"]').val();
	isCrossTab = (isCrossTab && isCrossTab.toString()) || '';
	var levelSelections = getSelectedLevels(form);
	var queryStr = '?async=1' + levelSelections + '&x=' + Math.random();
	jQuery(report_container).css('position', 'relative'); // test this out - make sure content does not shift
	jQuery.get(action + queryStr, function (data) {
		var hasSignon = checkForSignon(data);

		if (hasSignon >= 0) {
			window.location.reload(true);
			return;
		}

		dialog.find('.selection_content').html(data);
		var newform = dialog.find('.selection_content').find('form').get(0);
		// @ts-ignore
		initializeAjaxHelpers(newform); // _ajax_input_helpers.js, line 24
		setupPeriodAnalysis();
		// @ts-ignore
		buildComboboxes(newform); // _comboboxes.js, line 19
		// @ts-ignore
		initsetup(formid); // _report_init.js, line 6

		if (isCrossTab && jQuery.trim(isCrossTab) !== '') {
			jQuery(newform).find('select[name=C1]').val(isCrossTab);
		}

		jQuery('.run').remove();
	});

	// @ts-ignore
	dialog.toggle().draggable({ handle: '.selection-title' });
}
function doRequiredCheck($dialog){
	var requiredFieldsExist = $dialog.find('input[mrcrequired="required"], textarea[mrcrequired="required"]').length;

	if (requiredFieldsExist !== 0) {
			var validated = true;
			validated = ajaxFeatures.checkRequiredFields($dialog.get(0));
			if (!validated) {
				return false
				try {
					// @ts-ignore
					//requiredCallback(); // call callback, if exists
				} catch (err) {
					/* NOOP */
				}
			}
	}
	return true;
}
/**
 * Back button handler on maintainer form
 */
function doBack (el) { // eslint-disable-line no-unused-vars
	var form = jQuery(el).parents('form').get(0);
	var action = jQuery(form).attr('action');
	var url = action + '?refresh=1';
	window.location.href = url;
}

/**
 * Return data function handler, moved from inline HTML on 2015-11-17 by BD
 */
function getReturnData (data) { // eslint-disable-line no-unused-vars
	for (var i = 0, iLen = data.length; i < iLen; i++) {
		jQuery('[name="' + data[i] + '"]').not(':checkbox, :radio').val(data[++i]).end().filter('input[value="' + escape(data[i]) + '"]').prop('checked', true);
	}
}

/**
 * For Multiple record retrievals, register ajax suggest listeners
 */
function setupAjaxSuggestForms (form) {
	setupAjaxSuggest1(form);
}

/**
 * For WW retrievals, register ajax suggest listeners and set accessibility options
 */
function setMaintenanceAccessibility (form) {
	if (jQuery(form).attr('data-app_type') === 'WW') {
		setAccessibility(form);
		setupAjaxSuggest1(form);
	}

	if (jQuery(form).attr('data-app_type') === 'M') {
		setAccessibility(form);
	}
}

function setupRankingReportHeader (form) {
	if (jQuery(form).attr('data-app_type') === 'RNK') {
		setRankingHeading(form);
	}
}

function setRankingHeading (form) {
	form = jQuery(form);
	form.find('#colhead1').append(form.find('#colhead0').text());
	form.find('#colhead2').append(form.find('#colhead0').text());
}

/**
 * For calendar, set date options
 */
function setCalendarForm (form) {
	if (jQuery(form).attr('data-app_type') === 'C') {
		setCalendarFunctions(form);
	}
}

/* global init2 */
function setCalendarFunctions (initialForm) {
	var form = jQuery(initialForm);
	var action = form.attr('action');
	var currentMonth = form.find('#current_month').val();
	var currentYear = form.find('#current_year').val();
	// @ts-ignore
	init2(action, 'I', initialForm); // ajaxupdate.js, line 363
	setStart_EndYear(initialForm, currentYear);
	form.find('#year').val(currentYear);
	form.find('#month').val(currentMonth);
}

function setStart_EndYear (form, currentYear) {
	var len = form.year.length;
	var maxyear = form.year[len - 1].value;
	var minyear = form.year[0].value;

	if (currentYear < minyear) {
		minyear = currentYear;
		createYears(form.year, minyear, maxyear);
	} else if (currentYear > maxyear) {
		maxyear = currentYear;
		createYears(form.year, minyear, maxyear);
	}
}

function createYears (oSelect, minyear, maxyear) {
	var len = oSelect.length;
	var i;

	for (i = 0; i < len; i++) oSelect.remove(0);

	for (i = minyear; i <= maxyear; i++) {
		var oOption = /** @type {HTMLOptionElement} */ (document.createElement('OPTION'));

		oSelect.options.add(oOption);
		oOption.innerHTML = '' + i;
		oOption.value = '' + i;
	}
}

function handleAppSubmitForm (form) {
	if (jQuery(form).find('input[name="ajax_form"][value!="0"]').length > 0) {
		submitViaAjax(form);
	} else {
		form.submit();
	}
}

function goDate (n_p, el) { // eslint-disable-line no-unused-vars
	var form = /** @type {HTMLFormElement} */ (jQuery(el).parents('form').get(0));
	var year = jQuery(form).find('#year').val();
	year = parseInt(year, 10) || 0;
	var month = jQuery(form).find('#month').val();
	month = parseInt(month, 10) || 0;
	if (n_p == 0) { // prev
		month--;
		if (month == 0) {
			month = 12;
			year--;
		}
	} else { // next
		month++;
		if (month > 12) {
			month = 1;
			year++;
		}
	}

	jQuery(form).find('#year').val(year);
	form.year.value = year;
	jQuery(form).find('#month').val(month);
	jQuery(form).submit();
}

function setAjaxAutoFill (form) {
	// @ts-ignore
	jQuery(form).find('input[data-remotefields]').ajaxAutoFill(); // Check if there is Ajax Return Data AjaxHelper
    jQuery(form).find('span[data-remotefields]').ajaxAutoFill();
	var formType = jQuery(form).attr('data-app_type');

	if (formType === 'WW') {
		var div = jQuery(form).parents('.ajax_content').get(0);
		var maintainerForm = jQuery(div).find('form[data-app_type="M"]');

		// @ts-ignore
		jQuery(maintainerForm).find('input[data-remotefields]').ajaxAutoFill();
	}
}

/**
 * Hide/show accessibility options in maintainers
 */
function setAccessibility (form) {
	var mxRights = {};
	var elmts = [];

	form = jQuery(form);
	mxRights.action_mode = form.attr('data-action_mode');
	mxRights.work_with = form.find('#work_with').val();
	mxRights.allow_add = form.find('#allow_add').val();
	mxRights.allow_view = form.find('#allow_view').val();
	mxRights.allow_upd = form.find('#allow_upd').val();
	mxRights.allow_copy = form.find('#allow_copy').val();
	mxRights.allow_dlt = form.find('#allow_dlt').val();
	mxRights.authorized = form.find('#authorized').val();

	if (mxRights.work_with == 1 || mxRights.allow_add > 1) {
		elmts[0] = document.getElementsByName('madd');
	}
	if ( mxRights.allow_add > 1) {//for responsive maintainer
		elmts[0] = document.getElementsByName('madd2');
	}
	if (mxRights.allow_view > 1) {
		elmts[1] = document.getElementsByName('mview');
	}

	if (mxRights.allow_upd > 1) {
		elmts[2] = document.getElementsByName('mupdate');
		form.find('.action-update').hide();
	}

	if (mxRights.allow_add > 1 || mxRights.allow_copy > 1) {
		elmts[3] = document.getElementsByName('mcopy');
		form.find('.action-copy').hide();
	}

	if (mxRights.allow_dlt > 1) {
		elmts[4] = document.getElementsByName('mdelete');
		form.find('.action-delete').hide();
	}

	if ((mxRights.work_with == 1 || mxRights.work_with == 2) && (mxRights.action_mode === 'VVK')) {
		elmts[5] = document.getElementsByName('btn_accept');
		var buttons = jQuery('.save-proceed, .save-complete').addClass('proceed-next-tab').removeClass('save-proceed save-complete');
	}

	for (var i = 0; i < 7; i++) {
		if (elmts[i]) {
			for (var j = 0; j < elmts[i].length; j++) {
				if (elmts[i].item(j)) elmts[i].item(j).style.display = 'none';
			}
		}
	}
}

function addLoadingMessage (okButton, selector) { // eslint-disable-line no-unused-vars
	var html = '<div class="loading_ajax" style="z-index:9999;">Processing<br/><br/><i class="fa fa-spinner fa-spin"></i></div>';
	var dialog = jQuery(okButton).parents(selector).get(0);

	jQuery(dialog).append(html);
	console.warn('This function is deprecated, please use addLoadingMessageToContainer instead');
}

function removeLoadingMessage (okButton, selector) { // eslint-disable-line no-unused-vars
	var dialog = jQuery(okButton).parents(selector).get(0);
	var $loadingMessage = jQuery(dialog).find('.loading_ajax');

	$loadingMessage.remove();
	console.warn('This function is deprecated, please use removeLoadingMessageFromContainer instead');
}

function addLoadingMessageToContainer (container) {
	var $container = jQuery(container);
	var html = '<div class="loading_ajax" style="z-index:9999;">Processing<br/><br/><i class="fa fa-spinner fa-spin"></i></div>';

	$container.append(html);
}

function removeLoadingMessageFromContainer (container) { // eslint-disable-line no-unused-vars
	var $container = jQuery(container);
	var $loadingMessage = $container.find('.loading_ajax');

	$loadingMessage.remove();
}

/**
 * For ajax forms, perform the ajax request and replace HTML sent back
 */
/* global ajaxSuggestRunning: true */ // eslint-disable-line no-unused-vars
function loadViaAjax (parent, action, str, type, form) {
	jQuery.ajax({
		type: type,
		url: action,
		data: str,
		success: function (data) {
            var $oldDiv =  jQuery(parent);
            
            if(typeof data === 'undefined' || jQuery.trim(data) == ''){
                $oldDiv.trigger('mrc:contentLoaded');
                ajaxSuggestRunning = false;
                return;
            }
			var hasPromptError = checkForReportPromptError(data, parent);
			
			var $div = jQuery(data);
			var isWorkWith = $oldDiv.attr('data-work-with');
			var newForm;

			if (hasPromptError === 0) {
				$oldDiv.replaceWith($div);
				newForm = $div.find('form').get(0); // get the new form that was loaded into the div ajax container

				if (isWorkWith == '1') newForm = $div.find('form[data-app_type=M]').get(0);
			}

			// @ts-ignore
			ajaxSuggestRunning = false; // cancel ajax suggest request

			// @ts-ignore
			var hasSignon = checkForSignon(data, parent);

			if (hasSignon >= 0) {
				window.location.reload(true);

				return;
			}

			// Pass the new form and reset all page load event listeners for content inside the form
			if (getJSVersion() === 0) resetForm(newForm);

			var mrcRefreshEvent = new CustomEvent('mrcajaxformcallback', {
				detail: {},
				bubbles: true,
				cancelable: false
			});
			window.dispatchEvent(mrcRefreshEvent);
			$div.trigger('mrc:contentLoaded');
		},
		error: function (jqXHR, textStatus, errorThrown) {
			jQuery('.loading_ajax').remove();
			alert(errorThrown);
		},
		dataType: 'html'
	});
}

/**
 * Check If the signon page was 'ajaxed' into the page, if so we need to reload the parent page.
 */
function checkForSignon (data) {
	return data.indexOf('name="mrcpswd"');
}

/**
 * When using a report prompt in output page dialog, check to see if the ajax response was a prompt error
 */
function checkForReportPromptError (data, parent) {
	var hasPrompt = data.indexOf('mrc_rpt_prompt_table');

	if (hasPrompt < 0) return 0;

	var report_container = parent;
	var random = Math.random().toString().substring(2, 10);

	jQuery(report_container).attr('data-id', random);
	createReportPromptDialog(report_container, random);
	jQuery(report_container).find('.selection_content').html(data);

	var form = jQuery(report_container).find('form').get(0);
	var formid = jQuery(form).attr('id');

	// @ts-ignore
	initsetup(formid);
	jQuery('.run').remove();
	jQuery(report_container).css('position', 'relative');
	jQuery('.loading_ajax').remove();

	return 1;
}

/**
 * For ajax form submit, serialize the form, create query string and call ajax function
 */
function submitViaAjax (form) {
	var $form = jQuery(form);
	var method = $form.attr('method');
	var id = $form.attr('id');
	var str = $form.serialize();
	var type = $form.attr('data-app_type');
	var ajax = $form.find('input[name="ajax_form"]').val();
	/** @type {any} */
	var div = $form.parents('.ajax_content').get(0);
	var randomId;
	var butt;
	var searchButton;
	var btn_mnt;
	var action;

	str += '&form=' + id + '&async=1';

	if ((type === 'R' || type === 'RNK') && $form.find('input[name="reorder"]').val() !== 'Y' && ajax != '1') {
		if ($form.parents('.tab').length > 0) {
			randomId = $form.parents('.selection_dialog').eq(0).attr('data-parent-id');
			div = jQuery('div[data-id="' + randomId + '"]').append($form.parents('.selection_dialog').eq(0));
		} else {
			div = jQuery(div).parents('.ajax_content').get(0);
		}
	}

	butt = $form.find(':input[type="image"][clicked="true"]').attr('name');

	if (butt && butt !== '') str += '&' + butt + '.x' + '=1';

	searchButton = $form.find(':input[name="btn_search"][clicked="true"]').attr('name');

	if (searchButton && searchButton !== '') str += '&' + searchButton + '=1';

	searchButton = $form.find('.ok_button').attr('clicked');

	if (searchButton && searchButton === 'true') str += '&btn_search=1';

	btn_mnt = $form.find(':input[name="btn_mnt"][clicked="true"]').attr('name');

	if (btn_mnt && btn_mnt !== '') str += '&' + btn_mnt + '=1';

	str += '&x=' + Math.random();
	action = $form.attr('action');
	// @ts-ignore
	ajaxSuggestRunning = false;
	addLoadingMessageToContainer($form);

	//clear any possible modal blocker
	jQuery('.modal-backdrop').remove();
	loadViaAjax(div, action, str, method, form);
}

/**
 * After ajax form request, this is a callback to check and reset any necessary dom listeners
 * like widgets, ajax suggest, accessibility, etc
 */
/* global buildComboboxes */
function resetForm (form) {
	setupAjaxSuggestForms(form);
	setMaintenanceAccessibility(form);
	setCalendarForm(form);
	setupRankingReportHeader(form);
	// @ts-ignore
	buildComboboxes(form); // _comboboxes.js, line 19

	jQuery('input[name=\'reorder\']').val('N');
	// @ts-ignore
	initializeAjaxHelpers(form);
	setAjaxAutoFill(form);
	resetGridAdd(form);
	checkAutoRefresh(form);
	resetPaging(jQuery(form));
	setFixedPagination(form);

	try {
		// @ts-ignore
		jQuery(form).find('.fixed').fixedtableheader();
	} catch (err) {
		/* NOOP */
	}
}

/**
 * After form submit and response, check the auto_refresh hidden input, if it is greater
 * than 1, set timeout and refresh after elapsed time
 */
function checkAutoRefresh (form) {
	var refreshVal = jQuery(form).find('input[name="auto_refresh"]').val();
	var ajaxForm = jQuery(form).find('input[name="ajax_form"]').val();
	if(ajaxForm == 0){
		return;
	}
	var refresh = parseInt(refreshVal, 10) || 0;

	jQuery(form).find('.auto_refresh_select').val(refresh);

	if (refresh !== undefined && !isNaN(refresh) && refresh !== 0) {
		refresh = refresh * 1000;
		 // set page back to 1 before autorefreshing
		setTimeout(function () {
			jQuery(form).find('input[name="pageNum"]').val(1);
			submitViaAjax(form);
		}, refresh);
	}
}

/**
 * Setup ajax suggest
 */
function setupAjaxSuggest1 (form) {
	var app = jQuery(form).is('[data-program]') ? jQuery(form).attr('data-program') :  jQuery(form).attr('action');
     var pos = app.indexOf('-')
     if(pos >=0){
          app = app.substring(app.indexOf('-') + 1);
     }
	// @ts-ignore
	init2(app, 'I', form);
}

/**
 * Setup sort images
 */
/* global clickSortImage, s0, s1 */
function setSortImage1 (form) { // eslint-disable-line no-unused-vars
	var typ = form.sort_typ.value;
	var src = '';

	if (typ == 1) {
		// @ts-ignore
		src = s1.src;
	} else {
		// @ts-ignore
		src = s0.src;
	}

	// @ts-ignore
	clickSortImage.src = src;
}

/**
 * Handle when a user clicks a sort arrow
 */
function setSortAndSubmit (sortImage) {
	var col = sortImage.attr('data-sort_col');
	var typ = sortImage.attr('data-sort_type');
	var form = sortImage.parents('form').get(0);
	var id = jQuery(form).attr('id');

	form.reorder.value = 'Y';
	form.cur_sort_col.value = col;
	form.sort_typ.value = typ;

	// TODO: is the form ID to submit necessary still?
	if (jQuery(form).find('input[name="ajax_form"][value!="0"]').length > 0) {
		submitViaAjax(form);
	} else {
		var oForm = jQuery('#' + id); // need to do this for IRT....for some reason jQuery(form) wont work.
		oForm.submit();
	}
}

/**
 * For upgraded retrieval templates - 2014-08-14
 */
function setSortAndSubmit2 (sortImage) {
     var $el = jQuery(sortImage)
     var formInfo = formHandler.getForm(sortImage);
	var $form = formInfo.$form; //jQuery(sortImage).closest('form').get(0);
	var col = $el.attr('data-field');

     $form.find('[name=reorder]').val('Y');

	var $curSortCol = $form.find('[name=cur_sort_col]');
	var $sortType = $form.find('[name=sort_typ]');

	jQuery('.active-col').removeClass('active-col');
	jQuery(sortImage).closest('th').addClass('active-col');

	if ($curSortCol.val() === col) {
		$sortType.val(($sortType .val() == '0' ? '1' : '0'));
	} else {
		$sortType.val('1');
	}

	$curSortCol.val(col);

     if(!formInfo.isForm) {
          formHandler.doSubmit($el);
          return;
     }
	var id = $form.attr('id');

	// TODO: is the form ID to submit necessary still?
	if ($form.find('input[name="ajax_form"][value!="0"]').length > 0) {
		submitViaAjax($form.get(0));
	} else {
		var form = jQuery('#' + id); // need to do this for IRT....for some reason jQuery(form) wont work.
		form.submit();
	}
}

/**
 * Handle when a user clicks a sort arrow in a maintainer
 */
function setSortAndSubmitMaint (sortImage) {
	var col = sortImage.attr('data-sort_col');
	var typ = sortImage.attr('data-sort_type');
	var form = sortImage.parents('form').get(0);

	form.resort.value = 1;
	form.sort_col.value = col;
	form.sort_typ.value = (typ == 0 ? 'ASC' : 'DESC');

	handleAppSubmitForm(form);
}

/**
 * Handle when a user clicks a sort arrow in an IRT
 */
function setSortAndSubmitIrt (sortImage) {
	var col = sortImage.attr('data-sort_col');
	var typ = sortImage.attr('data-sort_type');
	var form = sortImage.parents('form').get(0);

	form.resort.value = 1;
	form.sort_col.value = col;
	form.sort_typ.value = (typ == 0 ? 'ASC' : 'DESC');

	handleAppSubmitForm(form);
}

/**
 * For report output 'make additional selections button' dynamically create a popup dialog
 */
function createReportPromptDialog (container, randomId) {
	if (jQuery(container).find('.selection_dialog').length > 0) {
		return jQuery(container).find('.selection_dialog');
	}

	var dialogHtml = '<div class="selection_dialog" data-parent-id="' + randomId + '" id="selection_dialog">' +
      '<div class="selection_title">Report Options <img class="selections_close" src="/mrcjava/image/delete16.png" /></div>' +
      '<div class="selection_content">&nbsp;</div>' +
      '<div class="selection_buttons">' +
        '<input class="ok_button_report selection_button btn btn-primary" name="ok_button" type="button" value="Ok"/>&nbsp;' +
        '<input class="cancel_button selection_button btn btn-default" type="button" name="cancel_button" value="Cancel"/>' +
      '</div>' +
    '</div>';
	var dialog = jQuery(dialogHtml);

	jQuery(container).append(dialog);

	return dialog;
}

function submitRankingPrompt (form) {
	var subtotal = jQuery(form).find(':input[name="subtotal"]');

	if (subtotal.length === 0) return;

	jQuery(form).find('input').each(function (i, el) {
		var name = jQuery(el).attr('name');

		if (name && name.substring(0, 2) === 'S_') jQuery(this).val(0);
	});

	var subtotalField = subtotal.val();

	jQuery(form).find('input[name="S_' + subtotalField + '"]').val(1);
}

/**
 * Substitution on report prompts, passes array ofselected levels
 */
function selectedLevels (selected_levels, form_id) { // eslint-disable-line no-unused-vars
	jQuery('form[name="' + form_id + '"]:has(\'.selected_levels\')').find('.selected_levels').find('input').prop('checked', false);

	for (var i = 0; i < selected_levels.length; i++) {
		jQuery('form[name="' + form_id + '"]:has(\'.selected_levels\')').find('input[name="' + selected_levels[i] + '"]').prop('checked', true);
	}
}

/**
 * Substitution on report prompts, passes array ofselected levels
 */
function setRankingLevels (measure, top10, dimension, sort_typ, form_id) { // eslint-disable-line no-unused-vars
	if (top10 != 0) {
		jQuery('form[name="' + form_id + '"]:has(select[name="top10"])').find('select[name="sort_typ"]').val(sort_typ);
		jQuery('form[name="' + form_id + '"]:has(select[name="top10"])').find('select[name="top10"]').val(top10);
		jQuery('form[name="' + form_id + '"]:has(select[name="top10"])').find('select[name="subtotal"]').val(dimension);
		jQuery('form[name="' + form_id + '"]:has(select[name="top10"])').find('select[name="cur_sort_col"]').val(measure);
	}
}

/**
 * When prompt is opened on output page, get which selections/levels to pass to it
 */
function getSelectedLevels (form) {
	var parms = '';
	var input;

	form.find('input[name^="S_"]').each(function (i, el) {
		parms += '&' + encodeURIComponent(jQuery(el).attr('name')) + '=' + jQuery(this).val();
	});

	form.find('input[name^="R0"]').each(function (i, el) {
		var RXXX = jQuery(el).val();
		RXXX = (RXXX && RXXX.toString()) || '';
		var pval = encodeURIComponent(RXXX);
		pval = pval.replace(/!/ig,'%21');
		parms += '&' + jQuery(el).attr('name') + '=' + pval;
	});

	form.find('input[name^="rls_"]').each(function (i, el) {
		parms += '&' + jQuery(el).attr('name') + '=' + jQuery(el).val();
	});

	form.find('input[name^="compVal"]').each(function (i, el) {
		parms += '&' + jQuery(el).attr('name') + '=' + jQuery(el).val();
	});

	form.find('input[name^="compareOn"]').each(function (i, el) {
		parms += '&' + jQuery(el).attr('name') + '=' + jQuery(el).val();
	});

	var appType = form.attr('data-app_type');

	if (appType === 'RNK') {
		input = form.find('input[name="sort_typ"]');

		parms += '&sort_typ=' + input.val();
		input = form.find('input[name="top10"]');
		parms += '&top10=' + input.val();
		input = form.find('input[name="cur_sort_col"]');
		parms += '&cur_sort_col=' + input.val();

		return parms;
	}

	input = form.find('input[name="D_DETAIL"]');
	parms += '&' + input.attr('name') + '=' + input.val();
	input = form.find('input[name="G_TOTAL"]');
	parms += '&' + input.attr('name') + '=' + input.val();
	input = form.find('input[name="rptPage"]');
	parms += '&' + input.attr('name') + '=' + input.val();

	return parms;
}

function doAppPaging (el) {
     var formInfo = formHandler.getForm(el);
	var form = jQuery(el).closest('form').get(0);
	var type = jQuery(el).attr('data-pagetype');

     if(!formInfo.isForm){
          formHandler.doSubmit(jQuery(el), type);
          return;
     }

	jQuery(el).after('<input type="hidden" name="' + type + '" value="1"/>');

	handleAppSubmitForm(form);
}

function resetPaging ($form) {
     var form = $form;
     if($form.is('[data-program]')){
          form = jQuery('[data-program="' + $form.attr('data-program') +'"]');
     }
	try {
		var pagenum = parseInt(form.find('input[name="pageNum"]').val(), 10) || 0;
		var totalPages = parseInt(form.find('input[name="total_pages"]').val(), 10) || 0;

		if (pagenum === totalPages) {
			form.find('a[data-pagetype="btn_end"], a[data-pagetype="btn_btmm"], a[data-pagetype="btn_btm"], a[data-pagetype="btn_next"]').removeClass('page-button');
		}

		if (pagenum === 1) {
			form.find('a[data-pagetype="btn_top"], a[data-pagetype="btn_prev"]').removeClass('page-button');
		}
	}catch (err) {
		/* NOOP */
	}
}

function setupPeriodAnalysis () {
	setEqualTo();

	jQuery('body').on('change', '.runtime-period', function (ev) {
		changePeriodUI(jQuery(this).val());
	});

	jQuery('body').on('change', '#compareOn', function (ev) {
		var cmp = jQuery(this).val();

		jQuery('.w2, .comp1').hide();

		if (cmp === 'custom') {
			jQuery('.w2, .comp1').show();
		}
	});

	// Set select list
	jQuery('#compareOn').val(jQuery('#compare_on').val());
	jQuery('.runtime-rls').each(function (i, el) {
		var valId = '#' + jQuery(el).attr('id') + 'v';
		if(jQuery(valId).length == 0) {
			return;
		}
		jQuery(el).val(jQuery(valId).val());
		if(jQuery(el).closest('.selection_dialog').length == 1){
			reportInit.setRuntimeSelectInputs(el);
		}
		
	});

	jQuery('.runtime-period').each(function (i, el) {
		var valId = '#' + jQuery(el).attr('id') + 'v';
		var relation = jQuery(valId).val();
		jQuery(el).val(relation);
		changePeriodUI(relation);
	});
}

function changePeriodUI (selectedRelationship) {
	var compareOn = jQuery('#compareOn').val();
	var rel = selectedRelationship;

	if (rel === 'EQ' || rel === 'GT' || rel === 'GE' || rel === 'LT' || rel === 'LE') {
		setEqualTo(compareOn);
	} else if (rel === 'RG') {
		setIsRange(compareOn);
	} else {
		setIsPeriod(compareOn);
	}
}

function setEqualTo (compareOn) {
	jQuery('.w1, .w2, .comp1').hide();
	jQuery('.range1').show();
	if (compareOn === 'custom') {
		jQuery('.comp1, .w2').show();
	}
}

function setIsRange (compareOn) {
	jQuery('.w1, .range1, .w2, .comp1').show();
	if (compareOn !== 'custom') {
		jQuery('.w2, .comp1').hide();
	}
}

function setIsPeriod (compareOn) {
	jQuery('.w1, .range1, .w2, .comp1').hide();
	if (compareOn === 'custom') {
		jQuery('.w2,.comp1').show();
	}
}
function copyInputs () {
	jQuery(':input[data-copyfrom]').each(function () {
		var copyFromInput = jQuery(this).attr('data-copyfrom'),
				val = jQuery('#' + copyFromInput).val();
		jQuery(this).val(val);
	});
}
